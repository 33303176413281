import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Accordion, Button, Form, Stack } from 'react-bootstrap';
import { useLocation, useSearchParams } from 'react-router-dom';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import FiltersContext from '../../contexts/FiltersContext';
import { DoubleRangeInput } from '../double-range-input/DoubleRangeInput';
import { RangeInputs } from '../range-inputs/RangeInputs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faArrowRotateLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import { Context } from '../../contexts/Context';
import { GiExpand } from "react-icons/gi";
import { BiCollapse } from "react-icons/bi";

import {
  GPUBrands,
  StorageSizes,
} from './filterInterfaces';
import './SideBar.scss';
import '../../common.scss';
import { searchItemDetails, searchItems, TESTING } from '../../queries';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import NewSearch from '../searcher/NewSearch';
import { useDebouncedCallback } from 'use-debounce';
import MinMaxInput, { filterInputChangeHandler, IFilterStatus, initialFilterStatus, rangeInputConstant } from '../range-inputs/NewRangeInput';

const PRICE_RANGE_MIN = 1;
const PRICE_RANGE_MAX = 99000;

const CPU_RANGE_MIN = 1;
const CPU_RANGE_MAX = 12;

const RAM_RANGE_MIN = 16;
const RAM_RANGE_MAX = 99000;


export const SideBar = () => {
  const screenSizeContext = useContext(ScreenSizeContext);
  const { showOffCanvas, setShowOffCanvas } = useContext(Context);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    clearFilters,
    setQueryParams,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,

    // RAM Size
    minRAM,
    setMinRAM,
    maxRAM,
    setMaxRAM,

    // CPU Speed
    minCPU,
    setMinCPU,
    maxCPU,
    setMaxCPU,

    // CPU Brands
    CPUAMD,
    setCPUAMD,
    CPUIntel,
    setCPUIntel,
    CPUApple,
    setCPUApple,
    CPUMediaTek,
    setCPUMediaTek,

    // GPU Brands
    GPUAMD,
    setGPUAMD,
    GPUIntel,
    setGPUIntel,
    GPUApple,
    setGPUApple,
    GPUNvidia,
    setGPUNvidia,
    GPUMediaTek,
    setGPUMediaTek,

	//Retailer
	RetailerWalmart,
	setRetailerWalmart,
	RetailerDell,
	setRetailerDell,
	RetailerBestBuy,
	setRetailerBestBuy,
	
    // Storage Type
    HDD,
    setHDD,
    SSD,
    setSSD,

    // Storage Size
    // Any,
    setAny,
    loading,

    textFilterValues, 
    setTextFilterValues,
  } = useContext(FiltersContext);

  const [activeKeys,setActiveKeys]=useState(['0', '1', '2', '3', '4', '5', '6','7'])

  const handleStorageChange = (value: string) => {
    const storageSetter = (min:number|"", max:number|'') =>{
    setTextFilterValues(prev => {
      const tempObj = JSON.parse(JSON.stringify(prev))
      tempObj[rangeInputConstant.StorageSize].current = 'checkbox';
      tempObj[rangeInputConstant.StorageSize].values.min = `${min}`;
      tempObj[rangeInputConstant.StorageSize].values.max = `${max}`;
      console.log(tempObj)
      return tempObj;
    })
    }
  if( value == StorageSizes.ANY || value == ''){
      storageSetter('','');
	}else if( value == StorageSizes.BELOW256GB){
    storageSetter('',256)
	}else if( value == StorageSizes.BETWEEN256GBN512GB){
    storageSetter(256,512)
	} else if( value == StorageSizes.BETWEEN512GBN1TB){
    storageSetter(512,1000)
	} else if( value == StorageSizes.ABOVE1TB){
    storageSetter(1000,'')
	}
  // Any only used for hitting the search , servs no other purpose
  setAny(any => !any)
  };

  const isStorageChecked = (min:string , max:string) =>{
    
  const isChecked = textFilterValues[rangeInputConstant.StorageSize].values.min == min && textFilterValues[rangeInputConstant.StorageSize].values.max == max 
  
  return isChecked;
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setQueryParams();
    }
  };

  // set min & max price from radio buttons
  const setMinMaxRAM = (val:string) => {
    const minMax : string[] = val?.split(':');
    setMinRAM(Number(minMax[0]));
    setMaxRAM(Number(minMax[1]));
  }
  const setMinMaxCPUSpeed = (val:string) => {
    const minMax : string[] = val?.split(':');
    setMinCPU(Number(minMax[0]));
    setMaxCPU(Number(minMax[1]));
}
  const setMinMaxPrice = (val:string) => {
    const minMax : string[] = val?.split(':');
    setMinPrice(Number(minMax[0]));
    setMaxPrice(Number(minMax[1]));
  }

  const setAccordion = (val:string) => {
    activeKeys.includes(val)?setActiveKeys(activeKeys.filter((keys)=>keys !== val)):setActiveKeys([...activeKeys,val])
  }

  const inputDisabler = (inputKey:string) =>{
    const resetValue = 
      { current: 'checkbox' , values: {min: "" , max:""} }
    
      setTextFilterValues((prevObj:IFilterStatus) => {
      const deepCopy = JSON.parse(JSON.stringify(prevObj));
      deepCopy[inputKey as keyof IFilterStatus] = resetValue;
      return deepCopy;
    });
  }
  
  const clearFilterHandler = () =>{
    clearFilters();
    setTextFilterValues(initialFilterStatus);
  }

  const sideclose =() =>{
    if(showOffCanvas){
      setShowOffCanvas(false);
    }
  }

  return (
    <>
      {loading ? (
        <div className="parentDisable">
          <div className="overlay-box">
            <Spinner animation="border" role="status" className="spinner">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <span />
      )}

    <Stack
      className={
        'sidebar ' +
        (screenSizeContext.isScreenWidthExtraLarge
          ? 'xl-sidebar'
          : screenSizeContext.isScreenWidthLarge
          ? 'lg-sidebar'
          : 'small-sidebar')
      }
      onKeyDownCapture={handleKeyDown}
    >
      <div className='d-flex align-items-center justify-content-between fs-14 px-2 mb-3 '>
        <a className="button-blue border-0 text-white fw-bold px-2 py-1 rounded-pill text-nowrap text-decoration-none w-100 me-2 text-center rounded d-flex align-items-center justify-content-center fw-bold" href="#" onClick={() => clearFilterHandler()}>Clear Filters</a> 
        <ReactTooltip id={"info"}/>
      
        <button className='rounded-pill button-blue border-0 text-white fw-bold px-2 py-1 w-100 text-center justify-content-center d-flex align-items-center' onClick={() => {
          setQueryParams()
          sideclose()  
        }}  >Search</button>
        
        <button data-tooltip-id={"info"}
                  data-tooltip-content={activeKeys.length> 0?"Collapse All":"Expand All"} className='button' onClick={()=>activeKeys.length> 0? setActiveKeys([]):setActiveKeys(["0","1","2","3","4","5","6","7"])}>
          {activeKeys.length>0? <BiCollapse />:<GiExpand />
              }
        </button>
      </div>
      <NewSearch sideClose={sideclose}/>
      <Accordion
     alwaysOpen
     activeKey={activeKeys}
   >
        <Accordion.Item eventKey="0">
          <Accordion.Header  onClick={()=>setAccordion("0")}>
            PRICE
          </Accordion.Header>
          <Accordion.Body>
            <Stack className='my-2'>
            <MinMaxInput filterKey={rangeInputConstant.Price} onChangeHandler={filterInputChangeHandler} textFilterValues={textFilterValues} state={textFilterValues} setter={setTextFilterValues} sideClose={sideclose}  />
            </Stack>

            <Stack direction="vertical"
            onClick={() => inputDisabler(rangeInputConstant.Price)}
            >
                {/* Min & Max Price radio Buttons */}
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="anyPrice">
              <Form.Check
                id="anyPrice"
                name="priceRange"
                type="radio"
                inline
                label="Any Price"
                onChange={(e) => setMinMaxPrice(e.target.value)}
                value={"0:0"}
                checked={textFilterValues[rangeInputConstant.Price].current == 'input' || minPrice == 0 && maxPrice == 0}
                // disabled={textFilterValues[rangeInputConstant.Price].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="priceRange-1">
              <Form.Check
                id="priceRange-1"
                name="priceRange"
                type="radio"
                inline
                label="Under $500"
                onChange={(e) => setMinMaxPrice(e.target.value)}
                value={"0:500"}
                checked={textFilterValues[rangeInputConstant.Price].current === 'checkbox' && minPrice == 0 && maxPrice == 500}
                // disabled={textFilterValues[rangeInputConstant.Price].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="priceRange-2">
              <Form.Check
                id="priceRange-2"
                name="priceRange"
                type="radio"
                inline
                label="$500 - $1000"
                onChange={(e) => setMinMaxPrice(e.target.value)}
                value={"500:1000"}
                checked={textFilterValues[rangeInputConstant.Price].current === 'checkbox' && minPrice == 500 && maxPrice == 1000}
                // disabled={textFilterValues[rangeInputConstant.Price].current === "input"}
              />  
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="priceRange-3">
              <Form.Check
                id="priceRange-3"
                name="priceRange"
                type="radio"
                inline
                label="$1000 - $1500"
                onChange={(e) => setMinMaxPrice(e.target.value)}
                value={"1000:1500"}
                checked={textFilterValues[rangeInputConstant.Price].current === 'checkbox' && minPrice == 1000 && maxPrice == 1500}
                // disabled={textFilterValues[rangeInputConstant.Price].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="priceRange-4">
              <Form.Check
                id="priceRange-4"
                name="priceRange"
                type="radio"
                inline
                label="$1500 - $2500"
                onChange={(e) => setMinMaxPrice(e.target.value)}
                value={"1500:2500"}
                checked={textFilterValues[rangeInputConstant.Price].current === 'checkbox' && minPrice == 1500 && maxPrice == 2500}
                // disabled={textFilterValues[rangeInputConstant.Price].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="priceRange-5">
              <Form.Check
                id="priceRange-5"
                name="priceRange"
                type="radio"
                inline
                label="Above $2500"
                onChange={(e) => setMinMaxPrice(e.target.value)}
                value={"2500: "}
                checked={
                  textFilterValues[rangeInputConstant.Price].current === 'checkbox' && 
                  minPrice == 2500 && maxPrice == 0}
                // disabled={textFilterValues[rangeInputConstant.Price].current === "input"}
              />
              </label>
            </Stack>
            </Stack>
          </Accordion.Body> 
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header  onClick={()=>setAccordion("1")}>
            RAM SIZE
          </Accordion.Header>
          <Accordion.Body>
            <Stack className='my-2'>
            <MinMaxInput filterKey={rangeInputConstant.RAMSize} onChangeHandler={filterInputChangeHandler} textFilterValues={textFilterValues} state={textFilterValues} setter={setTextFilterValues} sideClose={sideclose} />
            </Stack>
            <Stack direction='vertical'
            onClick={() => inputDisabler(rangeInputConstant.RAMSize)}
            
            >
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="anyRAMSize">
              <Form.Check
                id="anyRAMSize"
                name="RAMRange"
                type="radio"
                inline
                label="Any RAM Size"
                onChange={(e) => setMinMaxRAM(e.target.value)}
                value={"0:0"}
                checked={
                  textFilterValues[rangeInputConstant.RAMSize].current === 'input' || 
                  minRAM == 0 && maxRAM == 0 }
                // disabled={textFilterValues[rangeInputConstant.RAMSize].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="RAMRange-1">
              <Form.Check
                id="RAMRange-1"
                name="RAMRange"
                type="radio"
                inline
                label="Under 8GB"
                onChange={(e) => setMinMaxRAM(e.target.value)}
                value={"0:8"}
                checked={
                  textFilterValues[rangeInputConstant.RAMSize].current === 'checkbox' && 
                  minRAM == 0 && maxRAM == 8}
                // disabled={textFilterValues[rangeInputConstant.RAMSize].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="RAMRange-2">
              <Form.Check
                id="RAMRange-2"
                name="RAMRange"
                type="radio"
                inline
                label="8GB - 16GB"
                onChange={(e) => setMinMaxRAM(e.target.value)}
                value={"8:16"}
                checked={
                  textFilterValues[rangeInputConstant.RAMSize].current === 'checkbox' && 
                  minRAM == 8 && maxRAM == 16}
                // disabled={textFilterValues[rangeInputConstant.RAMSize].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="RAMRange-3">
              <Form.Check
                id="RAMRange-3"
                name="RAMRange"
                type="radio"
                inline
                label="16GB - 32GB"
                onChange={(e) => setMinMaxRAM(e.target.value)}
                value={"16:32"}
                checked={textFilterValues[rangeInputConstant.RAMSize].current === 'checkbox' && minRAM == 16 && maxRAM == 32}
                // disabled={textFilterValues[rangeInputConstant.RAMSize].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="RAMRange-4">
              <Form.Check
                id="RAMRange-4"
                name="RAMRange"
                type="radio"
                inline
                label="32GB - 64GB"
                onChange={(e) => setMinMaxRAM(e.target.value)}
                value={"32:64"}
                checked={
                  textFilterValues[rangeInputConstant.RAMSize].current === 'checkbox' &&
                  minRAM == 32 && maxRAM == 64}
                // disabled={textFilterValues[rangeInputConstant.RAMSize].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="RAMRange-5">
              <Form.Check
                id="RAMRange-5"
                name="RAMRange"
                type="radio"
                inline
                label="Above 64GB"
                onChange={(e) => setMinMaxRAM(e.target.value)}
                value={"64: "}
                checked={
                  textFilterValues[rangeInputConstant.RAMSize].current === 'checkbox' &&
                  minRAM == 64 && maxRAM == 0}
                // disabled={textFilterValues[rangeInputConstant.RAMSize].current === "input"}

              />
            </label>
            </Stack>
            </Stack>
            
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header  onClick={()=>setAccordion("2")}>
            CPU SPEED
          </Accordion.Header>
          <Accordion.Body>
{/*             <DoubleRangeInput */}
{/*               rangeMin={CPU_RANGE_MIN} */}
{/*               rangeMax={CPU_RANGE_MAX} */}
{/*               minValue={minCPU} */}
{/*               maxValue={maxCPU} */}
{/*               setMinValue={setMinCPU} */}
{/*               setMaxValue={setMaxCPU} */}
{/*               // eslint-disable-next-line @typescript-eslint/no-unused-vars */}
{/*               onChange={({ min, max }) => null} */}
{/*             /> */}
{/*             <br /> */}
{/*             <Stack className="range-inputs" direction="horizontal"> */}
{/*               <RangeInputs */}
{/*                 placeholder="GHz" */}
{/*                 rangeMin={CPU_RANGE_MIN} */}
{/*                 rangeMax={CPU_RANGE_MAX} */}
{/*                 minValue={minCPU} */}
{/*                 maxValue={maxCPU} */}
{/*                 setMinValue={setMinCPU} */}
{/*                 setMaxValue={setMaxCPU} */}
{/*               /> */}
{/*             </Stack> */}

            <Stack className='my-2'>
            <MinMaxInput filterKey={rangeInputConstant.CPUSpeed} onChangeHandler={filterInputChangeHandler} textFilterValues={textFilterValues} state={textFilterValues} setter={setTextFilterValues} sideClose={sideclose}  />
            </Stack>
            <Stack direction='vertical'
            onClick={() => inputDisabler(rangeInputConstant.CPUSpeed)}>
           
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="anyCPUSpeed">
              <Form.Check
                id="anyCPUSpeed"
                name="CPUSpeedRange"
                type="radio"
                inline
                label="Any CPU Speed"
                onChange={(e) => setMinMaxCPUSpeed(e.target.value)}
                value={"0:0"}
                checked={textFilterValues[rangeInputConstant.CPUSpeed].current === 'input' || minCPU == 0 && maxCPU == 0 }
                // disabled={textFilterValues[rangeInputConstant.CPUSpeed].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="CPUSpeedRange-1">
              <Form.Check
                id="CPUSpeedRange-1"
                name="CPUSpeedRange"
                type="radio"
                inline
                label="Under 1GHz"
                onChange={(e) => setMinMaxCPUSpeed(e.target.value)}
                value={"0:1"}
                checked={textFilterValues[rangeInputConstant.CPUSpeed].current === 'checkbox' && minCPU == 0 && maxCPU == 1}
                // disabled={textFilterValues[rangeInputConstant.CPUSpeed].current === "input"}
              />
            </label>  
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="CPUSpeedRange-2">
              <Form.Check
                id="CPUSpeedRange-2"
                name="CPUSpeedRange"
                type="radio"
                inline
                label="1GHz - 2GHz"
                onChange={(e) => setMinMaxCPUSpeed(e.target.value)}
                value={"1:2"}
                checked={textFilterValues[rangeInputConstant.CPUSpeed].current === 'checkbox' && minCPU == 1 && maxCPU == 2}
                // disabled={textFilterValues[rangeInputConstant.CPUSpeed].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="CPUSpeedRange-3">
              <Form.Check
                id="CPUSpeedRange-3"
                name="CPUSpeedRange"
                type="radio"
                inline
                label="2GHz - 3GHz"
                onChange={(e) => setMinMaxCPUSpeed(e.target.value)}
                value={"2:3"}
                checked={textFilterValues[rangeInputConstant.CPUSpeed].current === 'checkbox'  && minCPU == 2 && maxCPU == 3}
                // disabled={textFilterValues[rangeInputConstant.CPUSpeed].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="CPUSpeedRange-4">
              <Form.Check
                id="CPUSpeedRange-4"
                name="CPUSpeedRange"
                type="radio"
                inline
                label="3GHz - 4GHz"
                onChange={(e) => setMinMaxCPUSpeed(e.target.value)}
                value={"3:4"}
                checked={textFilterValues[rangeInputConstant.CPUSpeed].current === 'checkbox' && minCPU == 3 && maxCPU == 4}
                // disabled={textFilterValues[rangeInputConstant.CPUSpeed].current === "input"}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="CPUSpeedRange-5">
              <Form.Check
                id="CPUSpeedRange-5"
                name="CPUSpeedRange"
                type="radio"
                inline
                label="Above 4GHz"
                onChange={(e) => setMinMaxCPUSpeed(e.target.value)}
                value={"4: "}
                checked={textFilterValues[rangeInputConstant.CPUSpeed].current === 'checkbox' && minCPU == 4 && maxCPU == 0}
                // disabled={textFilterValues[rangeInputConstant.CPUSpeed].current === "input"}
              />
            </label>
            </Stack>
            </Stack>
         

          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header  onClick={()=>setAccordion("3")}>
            CPU BRAND
          </Accordion.Header>
          <Accordion.Body>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="cpuAMD">
              <Form.Check
                id="cpuAMD"
                inline
                label="AMD"
                onChange={(e) => setCPUAMD(e.target.checked)}
                checked={CPUAMD}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="cpuIntel">
              <Form.Check
                id="cpuIntel"
                inline
                label="Intel"
                onChange={(e) => setCPUIntel(e.target.checked)}
                checked={CPUIntel}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="cpuApple">
              <Form.Check
                id="cpuApple"
                inline
                label="Apple"
                onChange={(e) => setCPUApple(e.target.checked)}
                checked={CPUApple}
              />
            </label>
            </Stack>
            <Stack direction="horizontal" className="checkbox-filter">
            <label htmlFor="cpuMediaTek">
              <Form.Check
                id="cpuMediaTek"
                inline
                label="MediaTek"
                onChange={(e) => setCPUMediaTek(e.target.checked)}
                checked={CPUMediaTek}
              />
            </label>
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header  onClick={()=>setAccordion("4")}>
            GPU BRAND
          </Accordion.Header>
          <Accordion.Body>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="gpuAMD">
        <Form.Check
          id="gpuAMD"
          inline
          label="AMD"
          onChange={(e) => setGPUAMD(e.target.checked)}
          checked={GPUAMD}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="gpuIntel">
        <Form.Check
          id="gpuIntel"
          inline
          label="Intel"
          onChange={(e) => setGPUIntel(e.target.checked)}
          checked={GPUIntel}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="gpuApple">
        <Form.Check
          id="gpuApple"
          inline
          label="Apple"
          onChange={(e) => setGPUApple(e.target.checked)}
          checked={GPUApple}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="gpuNvidia">
        <Form.Check
          id="gpuNvidia"
          inline
          label="Nvidia"
          onChange={(e) => setGPUNvidia(e.target.checked)}
          checked={GPUNvidia}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="gpuMediaTek">
        <Form.Check
          id="gpuMediaTek"
          inline
          label="MediaTek"
          onChange={(e) => setGPUMediaTek(e.target.checked)}
          checked={GPUMediaTek}
        />
      </label>
    </Stack>
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="5">
  <Accordion.Header  onClick={()=>setAccordion("5")}>
    STORAGE TYPE
  </Accordion.Header>
  <Accordion.Body>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="hdd">
        <Form.Check
          id="hdd"
          inline
          label="HDD"
          onChange={(e) => {
            setHDD(e.target.checked);
          }}
          checked={HDD}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="ssd">
        <Form.Check
          id="ssd"
          inline
          label="SSD"
          onChange={(e) => {
            setSSD(e.target.checked);
          }}
          checked={SSD}
        />
      </label>
    </Stack>
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="6">
  <Accordion.Header  onClick={()=>setAccordion("6")}>
    STORAGE SIZE
  </Accordion.Header>
  <Accordion.Body>
          <Stack>
            <MinMaxInput filterKey={rangeInputConstant.StorageSize} onChangeHandler={filterInputChangeHandler} textFilterValues={textFilterValues}  state={textFilterValues} setter={setTextFilterValues} sideClose={sideclose} />
          </Stack>
          <Stack direction='vertical'>
        <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="storageAny">
        <Form.Check
          id="storageAny"
          name="storagesize"
          type="radio"
          inline
          label="Any"
          onChange={(e) => handleStorageChange(e.target.value)}
          value={StorageSizes.ANY}
          checked={textFilterValues[rangeInputConstant.StorageSize].current === 'input' || isStorageChecked('','')}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="storageBelow">
        <Form.Check
          id="storageBelow"
          name="storagesize"
          type="radio"
          inline
          label="Below 256GB"
          onChange={(e) => handleStorageChange(e.target.value)}
          value={StorageSizes.BELOW256GB}
          checked={textFilterValues[rangeInputConstant.StorageSize].current === 'checkbox' && isStorageChecked("","256")}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="storageBetween1">
        <Form.Check
          id="storageBetween1"
          name="storagesize"
          type="radio"
          inline
          label="Between 256GB & 512 GB"
          onChange={(e) => handleStorageChange(e.target.value)}
          value={StorageSizes.BETWEEN256GBN512GB}
          checked={textFilterValues[rangeInputConstant.StorageSize].current === 'checkbox' && isStorageChecked("256","512")}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="storageBetween2">
        <Form.Check
          id="storageBetween2"
          name="storagesize"
          type="radio"
          inline
          label="Between 512GB & 1TB"
          onChange={(e) => handleStorageChange(e.target.value)}
          value={StorageSizes.BETWEEN512GBN1TB}
          checked={textFilterValues[rangeInputConstant.StorageSize].current === 'checkbox' && isStorageChecked("512","1000")}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="storageAbove">
        <Form.Check
          id="storageAbove"
          name="storagesize"
          type="radio"
          inline
          label="Above 1TB"
          onChange={(e) => handleStorageChange(e.target.value)}
          value={StorageSizes.ABOVE1TB}
          checked={textFilterValues[rangeInputConstant.StorageSize].current === 'checkbox' && isStorageChecked("1000","")}
        />
      </label>
    </Stack>
    </Stack>
 
  </Accordion.Body>
</Accordion.Item>
<Accordion.Item eventKey="7">
  <Accordion.Header  onClick={()=>setAccordion("7")}>
    RETAILER
  </Accordion.Header>
  <Accordion.Body>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="retailerWalmart">
        <Form.Check
          id="retailerWalmart"
          inline
          label="Walmart"
          onChange={(e) => setRetailerWalmart(e.target.checked)}
          checked={RetailerWalmart}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="retailerDell">
        <Form.Check
          id="retailerDell"
          inline
          label="DELL"
          onChange={(e) => setRetailerDell(e.target.checked)}
          checked={RetailerDell}
        />
      </label>
    </Stack>
    <Stack direction="horizontal" className="checkbox-filter">
      <label htmlFor="retailerBestBuy">
        <Form.Check
          id="retailerBestBuy"
          inline
          label="BestBuy"
          onChange={(e) => setRetailerBestBuy(e.target.checked)}
          checked={RetailerBestBuy}
        />
      </label>
    </Stack>
  </Accordion.Body>
</Accordion.Item>
      </Accordion>

{/*       <div className="icon_container_parent"> */}
{/*           <div id="faicon" className="icon_container"> */}
{/*            <Button onClick={setQueryParams}> */}
{/*              <FontAwesomeIcon icon={faMagnifyingGlass} className="faicon" /> */}
{/*            </Button> */}
{/*            <Button onClick={clearFilters}>	  */}
{/*              <FontAwesomeIcon icon={faArrowRotateLeft} className="faicon" /> */}
{/*            </Button>	  */}
{/*           </div> */}
{/*       </div>     */}
      </Stack>
    </>
  );
};
