import React, { Dispatch, SetStateAction } from 'react';
import { SortTypes } from '../components/sidebar/filterInterfaces';
import { IFilterStatus, initialFilterStatus } from '../components/range-inputs/NewRangeInput';


export interface FiltersContextInterface {
  itemClickedSku: string;
  setItemClickedSku: Dispatch<SetStateAction<string>>;

  clearFilters: () => void;
  setQueryParams: (pageReset?:boolean) => void;

  sortBy: SortTypes;
  setSortBy: Dispatch<SetStateAction<SortTypes>>;

  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;

  numberOfPages: number;
  setNumberOfPages: Dispatch<SetStateAction<number>>;

  totalRecords: number;
  setTotalRecords: Dispatch<SetStateAction<number>>;

  itemName: string;
  setItemName: Dispatch<SetStateAction<string>>;

  minPrice: number;
  setMinPrice: Dispatch<SetStateAction<number>>;
  maxPrice: number;
  setMaxPrice: Dispatch<SetStateAction<number>>;

  // RAM Size
  minRAM: number;
  setMinRAM: Dispatch<SetStateAction<number>>;
  maxRAM: number;
  setMaxRAM: Dispatch<SetStateAction<number>>;

  // CPU Speed
  minCPU: number;
  setMinCPU: Dispatch<SetStateAction<number>>;
  maxCPU: number;
  setMaxCPU: Dispatch<SetStateAction<number>>;

  // CPU Brands
  CPUAMD: boolean;
  setCPUAMD: Dispatch<SetStateAction<boolean>>;
  CPUIntel: boolean;
  setCPUIntel: Dispatch<SetStateAction<boolean>>;
  CPUApple: boolean;
  setCPUApple: Dispatch<SetStateAction<boolean>>;
  CPUMediaTek: boolean;
  setCPUMediaTek: Dispatch<SetStateAction<boolean>>;

  // GPU Brands
  GPUAMD: boolean;
  setGPUAMD: Dispatch<SetStateAction<boolean>>;
  GPUIntel: boolean;
  setGPUIntel: Dispatch<SetStateAction<boolean>>;
  GPUApple: boolean;
  setGPUApple: Dispatch<SetStateAction<boolean>>;
  GPUNvidia: boolean;
  setGPUNvidia: Dispatch<SetStateAction<boolean>>;
  GPUMediaTek: boolean;
  setGPUMediaTek: Dispatch<SetStateAction<boolean>>;

  // Retailer
  RetailerWalmart: boolean;
  setRetailerWalmart: Dispatch<SetStateAction<boolean>>;
  RetailerDell: boolean;
  setRetailerDell: Dispatch<SetStateAction<boolean>>;
  RetailerBestBuy: boolean;
  setRetailerBestBuy: Dispatch<SetStateAction<boolean>>;
  
  // Storage Type
  HDD: boolean;
  setHDD: Dispatch<SetStateAction<boolean>>;
  SSD: boolean;
  setSSD: Dispatch<SetStateAction<boolean>>;

  // Storage Size
  Any: boolean;
  setAny: Dispatch<SetStateAction<boolean>>;
  
  newSearch: boolean;
  setNewSearch: Dispatch<SetStateAction<boolean>>;

  //loading
  loading:boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  
  // text field 
  textFilterValues:IFilterStatus, 
  setTextFilterValues:Dispatch<SetStateAction<IFilterStatus>>
}

const FiltersContext = React.createContext<FiltersContextInterface>({
  itemClickedSku: '',
  setItemClickedSku: () => null,

  clearFilters: () => null,
  setQueryParams: () => null,

  sortBy: SortTypes.PRICE_ASCENDING,
  setSortBy: () => null,

  pageNumber: 1,
  setPageNumber: () => null,

  numberOfPages: 1,
  setNumberOfPages: () => null,

  totalRecords:1,
  setTotalRecords: () => null,

  itemName: '',
  setItemName: () => null,

  minPrice: 0,
  setMinPrice: () => null,
  maxPrice: 0,
  setMaxPrice: () => null,

  // RAM Size
  minRAM: 0,
  setMinRAM: () => null,
  maxRAM: 0,
  setMaxRAM: () => null,

  // CPU Speed
  minCPU: 0,
  setMinCPU: () => null,
  maxCPU: 0,
  setMaxCPU: () => null,

  // CPU Brands
  CPUAMD: false,
  setCPUAMD: () => null,
  CPUIntel: false,
  setCPUIntel: () => null,
  CPUApple: false,
  setCPUApple: () => null,
  CPUMediaTek: false,
  setCPUMediaTek: () => null,

  // GPU Brands
  GPUAMD: false,
  setGPUAMD: () => null,
  GPUIntel: false,
  setGPUIntel: () => null,
  GPUApple: false,
  setGPUApple: () => null,
  GPUNvidia: false,
  setGPUNvidia: () => null,
  GPUMediaTek: false,
  setGPUMediaTek: () => null,

  // Retailer 	
  RetailerWalmart: false,
  setRetailerWalmart: () => null,
  RetailerDell: false,
  setRetailerDell: () => null,
  RetailerBestBuy: false,
  setRetailerBestBuy: () => null,
  
  // Storage Type
  HDD: false,
  setHDD: () => null,
  SSD: false,
  setSSD: () => null,

  // Storage Size
  Any: false,
  setAny: () => null,
  
  newSearch: false,
  setNewSearch: () => null,
  
  loading:false,
  setLoading: () => null,
  
  textFilterValues: initialFilterStatus, 
  setTextFilterValues: () => null
});

export default FiltersContext;
