export enum CPUBrands {
  AMD = 'AMD',
  INTEL = 'Intel',
  APPLE = 'Apple',
  MEDIATEK = 'MediaTek',
}

export enum GPUBrands {
  AMD = 'AMD',
  INTEL = 'Intel',
  APPLE = 'Apple',
  NVIDIA = 'Nvidia',
  MEDIATEK = 'MediaTek',
}

export enum Retailers {
  Walmart = 'WM',
  DELL = 'DELL',
  BestBuy = 'BB',
}

export enum StorageTypes {
  HDD = 'HDD',
  SSD = 'SSD',
}

export enum StorageSizes {
  ANY = 'any',
  BELOW256GB = 'bel256',
  BETWEEN256GBN512GB = '256_512',
  BETWEEN512GBN1TB = '512_1t',
  ABOVE1TB = 'abv_1t',
}
export enum RAMSizes {
  ANYRAM = 'any',
  BELOW8GBRAM = 'bel8GB',
  BETWEEN8GBn16GBRAM = '8_16',
  BETWEEN16GBn32GBRAM = '16_32',
  BETWEEN32GBn64GBRAM = '32_64',
  ABOVE64GB = 'abv_64',
}

export enum SortTypes {
  PRICE_DESCENDING = 'prd',
  PRICE_ASCENDING = 'pra',
  RAM_DESCENDING = 'rmd',
  RAM_ASCENDING = 'rma',
  CPU_SPEED_DESCENDING = 'cpd',
  CPU_SPEED_ASCENDING = 'cpa',
  STORAGE_SIZE_DESCENDING = 'std',
  STORAGE_SIZE_ASCENDING = 'sta',
}

export interface SearchKeys {
  pName: string;
  prl: number | string;
  ret: string;
  prh: number | string;
  raml: number | string;
  ramh: number | string;
  cpul: number | string;
  cpuh: number | string;
  cpubr: string;
  gpubr: string;
  styp: string;
  so: SortTypes;
  pno: number;
  rI?:string;
  sszl?:string;
  sszh?:string;
}
