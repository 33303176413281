import { useContext } from "react";
import { Button, Form, InputGroup } from "react-bootstrap"
import { BiMessageSquareCheck } from "react-icons/bi"
import { useDebouncedCallback } from "use-debounce";
import FiltersContext from "../../contexts/FiltersContext";
import React from 'react';

type params = {
  sideClose?:() => void
}
const NewSearch = (params:params) => {
    const { setQueryParams, itemName, setItemName } = useContext(FiltersContext);

    const onInputChange = useDebouncedCallback((value: string) => {
      setItemName(value);
    }, 0);
  
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onInputChange(event.currentTarget.value);
        setQueryParams();
      }
    };

  return (
    <div className="mx-2 new-searchbar rounded d-flex bg-white overflow-hidden border">
    <Form.Control
        className="border-0 rounded-0"
      placeholder="Search Product"
      aria-label="Search Product"
      value={itemName}
      aria-describedby="search-product-addon"
      onChange={(a) => onInputChange(a.target.value)}
      onKeyDownCapture={handleKeyDown} 
    />
    <button className="button-blue border-0 text-white px-2 fw-semibold" onClick={() => {
      setQueryParams()
      params.sideClose && params.sideClose()
      }}>
        Go
    </button>
    </div>
  )
}

export default NewSearch   