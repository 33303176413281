import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button} from 'react-bootstrap';
import FiltersContext from '../../contexts/FiltersContext';
//import OffCanvasContext from '../../contexts/OffCanvasContext';
//import { useToggleContext } from '../../contexts/OffCanvasProvider';
import { Context } from '../../contexts/Context';
import './CustomNavbar.scss';
import ReactDOM from 'react-dom';
import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  Offcanvas,
  Stack,
} from 'react-bootstrap';
import { BsFillFunnelFill, BsList } from 'react-icons/bs';

import { Searcher } from '../searcher/Searcher';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import { SideBar } from '../sidebar/SideBar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import '../../common.scss';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import ItemsContext from '../../contexts/ItemsContext';
import { searchItemDetails, searchItems, TESTING } from '../../queries';
import { CPUBrands, GPUBrands, Retailers, SortTypes, StorageSizes, StorageTypes } from '../sidebar/filterInterfaces';
import { rangeInputConstant } from '../range-inputs/NewRangeInput';
import { rangeInputParamExchanger } from '../../utils/functions';
import { rangeInputParamKey } from '../../utils/constant';

interface RouteObject {
  route: string;
  text: string;
}

//
const SEARCHER_COLLAPSE_ID = 'searcher-collapse';
const SEARCHER_UNDERHEADER_ID = 'searcher-underheader';

//
const routes: RouteObject[] = [
  {
    route: '/app',
    text: 'HOME',
  },
  {
    route: '/blog/',
    text: 'BLOG',
  },
  {
    route: '/about-us.html',
    text: 'ABOUT US',
  },
];

export const CustomNavbar = () => {
  const screenSizeContext = useContext(ScreenSizeContext);
  const [portalElementId, setPortalElementId] = useState<string>('');
  const { showOffCanvas, setShowOffCanvas } = useContext(Context);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isInitialRender = useRef(true);

  const submitOffcanvas = () => {
    setQueryParams();
  };

  // React's portal for the Searcher component
  const portal = useMemo(() => {
    const c = document.getElementById(portalElementId);
    return c ? ReactDOM.createPortal(<Searcher />, c) : null;
  }, [portalElementId]);

  // Based on the screen width, decides which element will be the container of the Searcher component
  useEffect(() => {
    if (
      screenSizeContext.innerWidth &&
      Number.isInteger(screenSizeContext.innerWidth)
    ) {
      const searcherContainerId = screenSizeContext.isScreenWidthLarge
        ? SEARCHER_COLLAPSE_ID
        : SEARCHER_UNDERHEADER_ID;
      setPortalElementId(searcherContainerId);
    }
  }, [screenSizeContext.innerWidth]);

  const {
    itemClickedSku,
    setQueryParams,
    setNumberOfPages,
    setTotalRecords,
    setItemName,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,

    //SortBy
    setSortBy,

    // RAM Size
    minRAM,
    setMinRAM,
    maxRAM,
    setMaxRAM,

    // CPU Speed
    minCPU,
    setMinCPU,
    maxCPU,
    setMaxCPU,

    // CPU Brands
    CPUAMD,
    setCPUAMD,
    CPUIntel,
    setCPUIntel,
    CPUApple,
    setCPUApple,
    CPUMediaTek,
    setCPUMediaTek,

    // GPU Brands
    GPUAMD,
    setGPUAMD,
    GPUIntel,
    setGPUIntel,
    GPUApple,
    setGPUApple,
    GPUNvidia,
    setGPUNvidia,
    GPUMediaTek,
    setGPUMediaTek,

	//Retailer
	RetailerWalmart,
	setRetailerWalmart,
	RetailerDell,
	setRetailerDell,
	RetailerBestBuy,
	setRetailerBestBuy,
	
    // Storage Type
    HDD,
    setHDD,
    SSD,
    setSSD,

    // Storage Size
    Any,
    setAny,

    newSearch,
    setNewSearch,
    loading, setLoading,
    textFilterValues, setTextFilterValues,
    setPageNumber
  } = useContext(FiltersContext);
  
  const sortParamHandler = (param:string) =>{
    
  switch (param) {
    case SortTypes.PRICE_DESCENDING:
    setSortBy(SortTypes.PRICE_DESCENDING)
    break;
    case SortTypes.PRICE_ASCENDING:
    setSortBy(SortTypes.PRICE_ASCENDING)
    break;
    case SortTypes.RAM_DESCENDING:
    setSortBy(SortTypes.RAM_DESCENDING)
    break;
    case SortTypes.RAM_ASCENDING:
    setSortBy(SortTypes.RAM_ASCENDING)
    break;
    case SortTypes.CPU_SPEED_DESCENDING:
    setSortBy(SortTypes.CPU_SPEED_DESCENDING)
    break;
    case SortTypes.CPU_SPEED_ASCENDING:
    setSortBy(SortTypes.CPU_SPEED_ASCENDING)
    break;
    case SortTypes.STORAGE_SIZE_DESCENDING:
    setSortBy(SortTypes.STORAGE_SIZE_DESCENDING)
    break;
    case SortTypes.STORAGE_SIZE_ASCENDING:
    setSortBy(SortTypes.STORAGE_SIZE_ASCENDING)
    break;
    default:
      setSortBy(SortTypes.PRICE_ASCENDING)
  }}

  const { setItems, items } = useContext(ItemsContext);

  const inputExchanger = {
    [rangeInputConstant.Price] : {min:minPrice , max:maxPrice},
    [rangeInputConstant.RAMSize] : {min:minRAM , max:maxRAM},
    [rangeInputConstant.CPUSpeed] : {min:minCPU , max:maxCPU},
  }


  const setStatesFromQueryParams = (): void => {
    const params = location.search.replace('?', '').split('&');
    for (const param of params) {
      const p = param.split('=');
      if (p?.[1]?.length > 0) {
        // eslint-disable-next-line no-case-declarations
        const stringVal = p[1];
        const numericVal = parseFloat(p[1]);
        switch (p[0]) {
          case 'pName':
            setItemName(stringVal);
            break;
          case 'prl':
            if (!isNaN(numericVal)) setMinPrice(numericVal);
            break;
          case 'prh':
            if (!isNaN(numericVal)) setMaxPrice(numericVal);
            break;
          case 'raml':
            if (!isNaN(numericVal)) setMinRAM(numericVal);
            break;
          case 'ramh':
            if (!isNaN(numericVal)) setMaxRAM(numericVal);
            break;
          case 'cpul':
            if (!isNaN(numericVal)) setMinCPU(numericVal);
            break;
          case 'cpuh':
            if (!isNaN(numericVal)) setMaxCPU(numericVal);
            break;
          case 'cpubr':
            if (stringVal.includes(CPUBrands.AMD)) setCPUAMD(true);
            if (stringVal.includes(CPUBrands.INTEL)) setCPUIntel(true);
            if (stringVal.includes(CPUBrands.APPLE)) setCPUApple(true);
            if (stringVal.includes(CPUBrands.MEDIATEK)) setCPUMediaTek(true);
            break;
          case 'gpubr':
            if (stringVal.includes(GPUBrands.AMD)) setGPUAMD(true);
            if (stringVal.includes(GPUBrands.INTEL)) setGPUIntel(true);
            if (stringVal.includes(GPUBrands.APPLE)) setGPUApple(true);
            if (stringVal.includes(GPUBrands.NVIDIA)) setGPUNvidia(true);
            if (stringVal.includes(GPUBrands.MEDIATEK)) setGPUMediaTek(true);
            break;
          case 'ret':
            if (stringVal.includes(Retailers.Walmart)) setRetailerWalmart(true);
            if (stringVal.includes(Retailers.DELL)) setRetailerDell(true);
            if (stringVal.includes(Retailers.BestBuy)) setRetailerBestBuy(true);
            break;
          case 'styp':
            if (stringVal.includes(StorageTypes.HDD)) setHDD(true);
            if (stringVal.includes(StorageTypes.SSD)) setSSD(true);
            break;
          case 'so':
            sortParamHandler(stringVal);
            break;
          case 'rI':
            rangeInputParamExchanger(stringVal , setTextFilterValues , inputExchanger , searchParams);
            break;
          case 'pno':
              if(!isNaN(numericVal)) setPageNumber(numericVal)
            break;
          case 'sszl' || 'sszh':
            if(!searchParams.get('rI')?.includes(rangeInputParamKey[rangeInputConstant.StorageSize])) rangeInputParamExchanger(stringVal , setTextFilterValues , inputExchanger , searchParams);
            break;
          default:
            break;
        }
      }
    }
  };
  
  const getItems = useCallback(async (queryParams: string): Promise<void> => {
    try{
      setLoading(true);
      const result = await searchItems(queryParams, TESTING);
      setItems(result.items);
      setNumberOfPages(result.numberOfPages);
      setTotalRecords(result.totalRecords);
    }finally{
      setLoading(false);
    }
  }, []);

  // Detects query params changes - this(search) is getting called on click of filter icon in small screens but seems harmless
  useEffect(() => {
    const searchParamsString = searchParams.toString();
    if (
      searchParamsString &&
      !searchParamsString.includes('skus') &&
      !searchParamsString.includes('websitecodes')
    ) {
      if( newSearch ){
        setNewSearch(false);
      }
      getItems('?' + searchParamsString);
      setStatesFromQueryParams();
    }
  }, [searchParams]);

  const getItemDetails = useCallback(
    async (itemSku: string): Promise<void> => {

      // Item to get details
      const auxItems = items;
      const itemIndex = auxItems.findIndex((i) => i.sku === itemSku);
      const item = auxItems[itemIndex];

      if (item) {
        const result = await searchItemDetails(
          itemSku,
          item.websiteCode,
          TESTING,  
        );
        if (result) {
          item.details = result;
          auxItems[itemIndex] = item;
          setItems([...auxItems]);
        }
      }
    },
    [items],
  );
  
  useEffect(() => {
    getItemDetails(itemClickedSku);
  }, [itemClickedSku]);

  // automatic search
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
      setQueryParams();
  }, [ minPrice, maxPrice, minRAM, maxRAM, minCPU, maxCPU, CPUAMD, CPUIntel, CPUApple, CPUMediaTek,
      GPUAMD, GPUIntel, GPUApple, GPUNvidia, GPUMediaTek,
      RetailerWalmart, RetailerDell, RetailerBestBuy,
      HDD, SSD, Any])

  return (
    <Stack className="custom-navbar fixed-top">
      <Navbar
        bg="white"
        variant="light"
        collapseOnSelect
        expand="lg"
        className={!screenSizeContext.isScreenWidthLarge ? 'small-navbar' : ''}
        expanded={false}
      >
        <div className="flex-container">
          <Navbar.Brand href="/app/?pName=">
            <img
              alt=""
              src="images/pw-icon_sm-transp.png"
              className="d-inline-block d-lg-none align-top pwimage"
            />
          </Navbar.Brand>
          <Navbar.Brand href="/app/?pName=">
            <img
              alt=""
              src="images/pwlogo_blue.png"
              className="d-inline-block d-none d-lg-block align-top"
            />
          </Navbar.Brand>

          {/* Portal for the searcher input */}
          <Stack id={SEARCHER_UNDERHEADER_ID}
           className={
           'searcher-underheader ' +
           (screenSizeContext.isScreenWidthLarge ? ' d-none' : '')
          }>
          <Navbar.Toggle data-bs-toggle="offcanvaspw" 
            children={<BsFillFunnelFill />}
            onClick={() => setShowOffCanvas(!showOffCanvas)}
			id="filter-icon"
          />{' '}
         </Stack>
        {screenSizeContext.isScreenWidthLarge && (
          <div className={'flex-container large '}>
            <Navbar.Collapse id={SEARCHER_COLLAPSE_ID} />
          </div>
        )}
        {/* hiding previouse search component */}
        {/* {portal} */}
          <Navbar.Collapse className="">
            <Container
              className={
                'routes-container ' +
                (screenSizeContext.isScreenWidthExtraLarge
                  ? ''
                  : screenSizeContext.isScreenWidthLarge
                  ? 'lg-routes'
                  : 'small-routes')
              }
            >
              <Nav className="float-end">
                {routes.map((d) => (
                  <a href={d.route} key={d.route}>{d.text}</a>
                ))}
              </Nav>
            </Container>
          </Navbar.Collapse>
        <Navbar.Toggle
          children={
            <Dropdown>
              <Dropdown.Toggle as="div">
                <BsList />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                {routes.map((d) => (
                  <Dropdown.Item key={d.route} href={d.route}>
                    {d.text}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          }
        />
        </div>
      </Navbar>

      {/* Sidebar's offcanvas */}
      {/* The styles for this are placed at the index.scss */}
      {!screenSizeContext.isScreenWidthLarge &&
        !screenSizeContext.isScreenWidthExtraLarge && (
          <Offcanvas id="offcanvaspw" 
            show={showOffCanvas}
            // backdrop={false}
            onHide={setShowOffCanvas}
          >
            <Offcanvas.Header closeButton><Offcanvas.Title><span className="offcanvas-title">FILTER SPECS</span></Offcanvas.Title></Offcanvas.Header>
            <Offcanvas.Body>
              <SideBar  />	  
               {/*<div className="icon_container_parent">
                  <div id="faicon" className="icon_container">
                  <Button onClick={submitOffcanvas}>
                     <FontAwesomeIcon icon={faMagnifyingGlass} className="faicon" />
                  </Button>
                  <Button onClick={clearFilters}>	 
                      <FontAwesomeIcon icon={faArrowRotateLeft} className="faicon" />
                  </Button>	 
                  </div>
               </div> */}
            </Offcanvas.Body>
          </Offcanvas>
        )}
    </Stack>
  );
};
