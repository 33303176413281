import { ChangeEvent, Dispatch, useContext } from "react";
import React from "react";
import FiltersContext from "../../contexts/FiltersContext";

export interface IFilterStatus {
  [key: string]: {
    current: "checkbox" | "input";
    values: { min: number | string | ""; max: number | string | "" };
  };
}
export const initialFilterStatus: IFilterStatus = {
  Price: { current: "checkbox", values: { min: "", max: "" } },
  RAMSize: { current: "checkbox", values: { min: "", max: "" } },
  CPUSpeed: { current: "checkbox", values: { min: "", max: "" } },
  StorageSize: { current: "checkbox", values: { min: "", max: "" } },
};
export const rangeInputConstant = {
  Price: "Price",
  RAMSize: "RAMSize",
  CPUSpeed: "CPUSpeed",
  StorageSize: "StorageSize",
};
export interface FilterRangeProps {
  filterKey: string;
  allowDecimals?: boolean;
  textFilterValues: IFilterStatus;
  onChangeHandler: any;
  state: any;
  setter: Dispatch<any>;
  sideClose?: () => void
}
export const filterInputChangeHandler = (
  inputKey: string,
  values: { key: keyof IFilterStatus[string]["values"] },
  event: ChangeEvent<HTMLInputElement>,
  state: any,
  setter: Dispatch<any>
) => {
  const value = event.target.value;
  let regex: RegExp;

  if (inputKey === rangeInputConstant.CPUSpeed) {
    regex = /^(?:[0-9](?:\d{0,2}(?:,\d{3})+|\d*))?(?:\.\d{0,2})?$/;
  } else {
    regex = /^(?:[0-9](?:\d{0,2}(?:,\d{3})+|\d*))?(?:\.\d+)?$/;
  }

  if (value === "" || regex.test(value)) {
    const tempObj: IFilterStatus = JSON.parse(JSON.stringify(state));
    if (tempObj[inputKey as keyof IFilterStatus].current === "checkbox") {
      tempObj[inputKey as keyof IFilterStatus].current = "input";

      if(inputKey === rangeInputConstant.StorageSize){
        tempObj[inputKey].values.min = "";
        tempObj[inputKey].values.max = "";
      }
    }
    tempObj[inputKey as keyof IFilterStatus].values[values.key] = value;
    setter(tempObj);
  }
};
const preFixUnits = {
  [rangeInputConstant.Price]: "$",
  [rangeInputConstant.RAMSize]: "GB",
  [rangeInputConstant.CPUSpeed]: "GHz",
  [rangeInputConstant.StorageSize]: "GB",
};
const MinMaxInput = ({
  filterKey,
  onChangeHandler,
  textFilterValues,
  allowDecimals = false,
  state,
  setter,
  sideClose 
}: FilterRangeProps) => {
  const { setQueryParams } = useContext(FiltersContext);
  const isCheckbox = textFilterValues[filterKey].current === 'checkbox';
  
  return (
    <div className="d-flex align-items-end">
      <div className="prefixUnits mb-1 me-1">
        {filterKey === rangeInputConstant.Price && preFixUnits[filterKey]}
      </div>
      <div className="input-wrapper">
        <label htmlFor="max-value">Min</label>
        <input
          className="w-100 rounded-2 border border-2"
          id="max-value"
          type="text"
          inputMode={"numeric"}
          value={!isCheckbox && textFilterValues[filterKey]?.values.min || ""}
          onChange={(event) =>
            onChangeHandler(filterKey, { key: "min" }, event, state, setter)
          }
        />
      </div>
      {filterKey !== rangeInputConstant.Price && (
        <div className="prefixUnits mb-1 ms-1 me-2">
          {preFixUnits[filterKey]}
        </div>
      )}
      {filterKey === rangeInputConstant.Price && (
        <div className="prefixUnits mb-1 ms-2 me-1">
          {preFixUnits[filterKey]}
        </div>
      )}
      <div className="input-wrapper flex-1">
        <label htmlFor="max-value">Max</label>
        <input
          className="w-100 rounded-2 border border-2"
          id="max-value"
          type="text"
          value={!isCheckbox && textFilterValues[filterKey]?.values.max || ""}
          onChange={(event) =>
            onChangeHandler(filterKey, { key: "max" }, event, state, setter)
          }
          inputMode={allowDecimals ? "decimal" : "numeric"}
        />
      </div>
      {filterKey !== rangeInputConstant.Price && (
        <div className="prefixUnits mb-1 ms-1">{preFixUnits[filterKey]}</div>
      )}
      <button
        className="button-blue fs-6 border-0 px-2 fw-semibold rounded-2 text-white ms-2 "
        onClick={() => {
          setQueryParams()
          sideClose && sideClose()
        }}
      >
        Go
      </button>
    </div>
  );
};

export default MinMaxInput;
