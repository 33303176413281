import React, { useContext, useEffect, useState } from 'react';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import './NoItemFound.scss';
import '../../common.scss';
import { Footer } from '../footer/Footer';
import ItemsContext from '../../contexts/ItemsContext';
import FiltersContext from '../../contexts/FiltersContext';
import { validateFiltersFromUrl } from '../../utils/functions';

// This component is displayed when no results were received from the API call

export const searchPerformed = (): boolean => {
  console.log('url--' + window.location.href );
  return window.location.href.includes("pName=");
};

export const NoItemFound = () => {
  const searched = searchPerformed()
  const screenSizeContext = useContext(ScreenSizeContext);
  const itemsContext = useContext(ItemsContext);
  const filterContext = useContext(FiltersContext);
  const noResult = searched && !filterContext.loading && itemsContext.items.length <= 0;
  const [errors , setErrors] = useState<string[]>([]);

  useEffect(() => {
    const validationErrors = validateFiltersFromUrl();
    setErrors(validationErrors);
  }, [window.location.search]); // Re-run validation when URL parameters change

  return (
    <div className={`notfound-container ${itemsContext.items.length > 0 && 'd-none'}`}>
    {<p className={`no-result-found ${noResult ? 'd-block' : `d-none` }  `}>No Results Found</p>}
    {errors.length > 0 && errors.map((error , index) =>{
      return <p key={index} className={`no-result-found ${noResult ? 'd-block' : `d-none` }  `}>{error}</p>
    })}
    <div
      className={
        'no-item-found-container ' +
        (screenSizeContext.isScreenWidthExtraLarge
          ? 'xl-no-item-found-container'
          : screenSizeContext.isScreenWidthLarge
          ? 'lg-no-item-found-container'
          : 'small-no-item-found-container')
      }
    >
      <div id="ctasection"
        className={
          'no-item-found-content ' +
          (!screenSizeContext.isScreenWidthLarge ? 'small-font-size' : '')
        }
      >			
     {/*<div className="parentDisable">
     <div className="overlay-box">	  
        <Spinner animation="border" role="status" className="spinner">
           <span className="visually-hidden">Loading...</span>
        </Spinner>
       </div>
      </div> */}
      <div className="parent">
     { !searched&&<>
         <div className="first"></div>
         <div className="last">
            <h1>Find the best laptop your money can buy!</h1>
         </div>
         </>}
         <div className="top-text">
          { /* <h3>FIND THE BEST PRICE WITH THE RIGHT SPECS FOR YOUR NEXT LAPTOP !!!</h3> */ }
{!searched &&<>
			<div className="search-detail mt-3 mb-3">One click to search Dell, Walmart & Best Buy!</div>
			<div className="search-detail mb-3">Search by Technical Specs not available on retailers websites!</div></>}
            <img
                alt="Sleek laptop"
                src="images/laptop_home.png"
                className="d-inline-block align-top"/>
          </div>
        </div>

      </div>
      <p className="brand_disclaimer">NOTE: DELL is a trademark of Dell Technologies. Walmart is a trademark of Walmart Inc. Best Buy is a trademark of Best Buy Co., Inc.</p>
      <Footer />
    </div>
    </div>
  );
};
