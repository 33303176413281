import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import ReactDOM from "react-dom";
import ItemsContext from "../../contexts/ItemsContext";
import ItemsToCompareContext from "../../contexts/ItemsToCompareContext";
import ScreenSizeContext from "../../contexts/ScreenSizeContext";
import { searchItemDetails, TESTING } from "../../queries";
import { RetailerLogo, RetailerLogosName } from "../retailer-logo/RetailerLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import {
  CPUBrands,
  GPUBrands,
  StorageTypes,
} from "../sidebar/filterInterfaces";
import "../../common.scss";
import "./ItemCard.scss";
import { Tooltip as ReactTooltip } from "react-tooltip";

export interface ItemDetails {
  name: string;
  description: string | null;
  salePrice: number;
  regularPrice: number;
  upc: string | null;
  sku: string;
  modelNumber: string | null;
  merchantURL: string;
  imageURL: string;
  secondaryImageURL: string | null;
  thumbnailImageURL: string | null;
  color: string | null;
  secondaryMarket: string | null;
  condition: string;
  depthInInches: number;
  heightInInches: number;
  widthInInches: number;
  weight: number | null;
  freeShipping: string | null;
  brand: string;
  category: string;
  categoryPath: string | null;
  websiteCode: string;
  retailersDisclaimer: string | null;
  dimensions: string | null;
  misc: string | null;
  screenSize: string;
  screenResolution: string;
  touchScreen: string | null;
  opticalDrives: string | null;
  storageSize: string;
  storageType: StorageTypes;
  supportedWireless: string | null;
  wirelessProtocol: string | null;
  ethernet: string | null;
  bluetooth: string | null;
  manufacturerWarrantyParts: string | null;
  manufacturerWarrantyLabor: string | null;
  ieee1394: string | null;
  totalUSBPorts: number;
  usb2Ports: string | null;
  usb3Ports: string | null;
  usbTypeCPorts: string | null;
  thunderbolt2Ports: string;
  thunderbolt3Ports: string;
  thunderbolt4Ports: string;
  cpuSpeed: string;
  cpuModelNumber: string | null;
  cpuCores: string | null;
  cpuBrand: CPUBrands;
  graphicsType: string | null;
  gpuBrand: GPUBrands;
  osName: string;
  osArchitecture: string | null;
  ramSize: string;
  ramType: string;
  cameraHighestResolution: number;
  mic: string | null;
  mediaCardReader: string | null;
  configurable: string;
  batteryLife: string | null;
  audio: string | null;
}
export interface Item {
  sku: string;

  itemName: string;
  regularPrice: string;
  salePrice: string;
  description: string;
  itemUrl: string;
  imageUrl: string;

  sourceWebsiteCode?: string;
  merchantUrl: string;
  merchantsItemId: string | null; //
  itemShortDescr: string | null;
  longDescr: string | null;
  upc: string | null;
  thumbnailImageUrl: string | null;
  logoFileName: string | null;
  affiliateId: string | null;

  cpuBrand: CPUBrands;
  cpuSpeed: string;
  ramSize: string;
  storageSize: string;
  screenSize: string;
  screenResolution: string;
  websiteCode: string;

  details?: ItemDetails;
  retailer?:string
}

// const Heart = ({ favorited }: { favorited: boolean }) => {
//   return favorited ? (
//     <BsFillSuitHeartFill className="heart-icon favorited" />
//   ) : (
//     <BsSuitHeart className="heart-icon" />
//   );
// };

export const valueIsNull = (value: unknown): boolean => {
  return !value || value == 'null';
};

export const formatValue = (
  value: unknown,
  ifNotNull: string | null = null,
  ifNull = 'Unavailable',
): string => {
  const isNull = valueIsNull(value);
  return !isNull ? ifNotNull ?? `${value}` : ifNull;
};

export const openExternalLink = (url: string) => {
  window.open(url, '_blank');
};

export const Price = ({
  regularPrice,
  salePrice,
  onClick = () => null,
}: {
  regularPrice: string;
  salePrice: string;
  onClick: () => void;
}) => {
  if (!regularPrice && !salePrice) return null;
  return (
    <Col className="prices-wrapper" onClick={onClick}>
      {!salePrice || salePrice == regularPrice ? (
        <span className="old-price">${regularPrice}</span>
      ) : (
        <>
          <span className="final-price">${salePrice}</span>
          {regularPrice && (
            <span className="old-price strike-price">${regularPrice}</span>
          )}
        </>
      )}
    </Col>
  );
};

export const ItemCard = ({
  item,
  onClick,
  onMouseEnter,
  viewIsGrid
}: {
  item: Item;
  onClick: () => void;
  onMouseEnter: () => void;
  viewIsGrid: boolean
}) => {
  // Constants that identify cards' sections
  const ACTIONS_PRICE_ID = `ACTIONS_PRICE_ID_${item.sku}`;
  const ACTIONS_RETAILER_LOGO_ID = `ACTIONS_RETAILER_LOGO_ID_${item.sku}`;

  const MEDIA_PRICE_ID = `MEDIA_PRICE_ID_${item.sku}`;
  const MEDIA_RETAILER_LOGO_ID = `MEDIA_RETAILER_LOGO_ID_${item.sku}`;
  const MEDIA_XSMALL_RETAILER_LOGO_ID = `MEDIA_XSMALL_RETAILER_LOGO_ID_${item.sku}`;

  const screenSizeContext = useContext(ScreenSizeContext);
  const { items, setItems } = useContext(ItemsContext);
  const { itemsToCompare, setItemsToCompare } = useContext(
    ItemsToCompareContext
  );

  const [retailerLogoPortalElementId, setRetailerLogoPortalElementId] =
    useState<string>('');
  const [pricePortalElementId, setPricePortalElementId] = useState<string>('');

  //
  useEffect(() => {
    if (
      screenSizeContext.innerWidth &&
      Number.isInteger(screenSizeContext.innerWidth)
    ) {
      setRetailerLogoPortalElementId(
        screenSizeContext.isScreenWidthLarge
          ? ACTIONS_RETAILER_LOGO_ID
          : screenSizeContext.isScreenWidthXSmall 
            ? MEDIA_XSMALL_RETAILER_LOGO_ID 
            : MEDIA_RETAILER_LOGO_ID,
      );
      setPricePortalElementId(
        screenSizeContext.isScreenWidthLarge
          ? ACTIONS_PRICE_ID
          : MEDIA_PRICE_ID,
      );
    }
	setRetailerLogoPortalElementId(ACTIONS_RETAILER_LOGO_ID);
	setPricePortalElementId(ACTIONS_PRICE_ID); 
	setRetailerLogoPortalElementId(MEDIA_RETAILER_LOGO_ID);
	setPricePortalElementId(MEDIA_PRICE_ID);
  }, [screenSizeContext.innerWidth]);

  const disableCheckBox = useCallback((): boolean => {
    if (screenSizeContext.isScreenWidthLarge && itemsToCompare.length >= 5)
      return true;
    if (!screenSizeContext.isScreenWidthLarge && itemsToCompare.length >= 2)
      return true;
    return false;
  }, [screenSizeContext.isScreenWidthLarge, itemsToCompare.length]);

  const findIndex = useCallback((): number => {
    return itemsToCompare.findIndex((i) => i.sku === item.sku);
  }, [itemsToCompare]);

  const updateItemsToCompare = useCallback(
    async (checked: boolean): Promise<void> => {
      if (checked) {
        if (item.details) setItemsToCompare([...itemsToCompare, item]);
        else {
          // Get item details if they haven't been retrieved yet
          const auxItems = items;
          const itemIndex = auxItems.findIndex((i) => i.sku === item.sku);
          const result = await searchItemDetails(
            item.sku,
            item.websiteCode,
            TESTING,
          );
          if (result) {
            item.details = result;
            auxItems[itemIndex] = item;
            setItems([...auxItems]);
            setItemsToCompare([...itemsToCompare, item]);
          }
        }
      } else {
        const itemIndex = findIndex();
        if (itemIndex > -1) {
          const auxItemsToCompare = itemsToCompare;
          auxItemsToCompare.splice(itemIndex, 1);
          setItemsToCompare([...auxItemsToCompare]);
        }
      }
    },
    [itemsToCompare],
  );

  const CheckBox = () => (
    <Form.Check
      type="checkbox"
      label="Compare"
      disabled={disableCheckBox()}
      checked={findIndex() > -1 ? true : false}
      onChange={(a) => updateItemsToCompare(a.target.checked)}
    />
  );

  return (
    <div>
    {viewIsGrid ? (
      // Grid Card
      <Card className="grid-card-item h-100">
        <Card.Body>
          <div className='card-inner-wrapper'>
            <div className='price-and-retailer'>
              <div>
                {/* Price */}
                <Price
                  regularPrice={item.regularPrice}
                  salePrice={item.salePrice}
                  onClick={() => openExternalLink(item.merchantUrl)}
                />
              </div>
              {/* Retailer Logo */}
              <div className='grid-retailer-logo my-1'>
                <RetailerLogo websiteCode={item.websiteCode as RetailerLogosName} />
              </div>
            </div>
            
            {/* Image */}
            <div className="img-wrapper">
              <Card.Img className="" src={item.imageUrl} />
            </div>
            
              <div className="card-more-details">
              <div className="grid-container">
                <div className="section-1">
                  <div className="mt-1 card-text"><span className="specs-label">RAM: </span> <span className="specs-value">{formatValue(item.ramSize, `${item.ramSize}GB`)}</span></div>
                  <div className="mt-1 card-text">
                  <span className="specs-label">Storage: </span> <span className="specs-value">{formatValue(item.storageSize, `${item.storageSize}GB`)}</span>
                  </div>

                  <div className="mt-1 card-text"><span className="specs-label">CPU brand: </span> <span className="specs-value">{formatValue(item.cpuBrand)}</span></div>
                </div>
                <div className="section-2 ">
                  <div className="mt-1 card-text"><span className="specs-label">CPU Speed: </span> <span className="specs-value">{formatValue(item.cpuSpeed, `${item.cpuSpeed}GHz`)}</span></div>
                  <div className="mt-1 card-text"><span className="specs-label">Screen resolution: </span> <span className="specs-value">{formatValue(item.screenResolution)}</span></div>
                  <div className="mt-1 card-text"><span className="specs-label">Display size: </span> <span className="specs-value">{formatValue(item.screenSize)}</span></div>
                </div>
                </div>
              </div>

            {/* Title */}
            <div className="mt-1">
              <Button className="button-link" variant="link" onClick={() => openExternalLink(item.merchantUrl)}>
                {item.itemName}
              </Button>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="px-0">
          <div className="d-flex justify-content-between">
            <div className="actions-compare px-2">
              <input
                id={`${item.sku}`}
                className="form-check-input"
                type="checkbox"
                disabled={disableCheckBox()}
                checked={findIndex() > -1 ? true : false}
                onChange={(a) => updateItemsToCompare(a.target.checked)}
              />{" "}
              <label htmlFor={`${item.sku}`} className="form-check-label card-text">Compare</label>
            </div>
            <div className="actions-show-details px-2 d-flex justify-content-center align-items-center">
              <Button
                className="button-link"
                variant="link"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
              >
                {" "}
                Show Details{" "}
              </Button>
              {/* <div className="px-2 align-bottom"> */}
                <ReactTooltip id={"disclaimer_" + item.sku} className="ttclass" clickable={true}/>
                <button data-tooltip-id={"disclaimer_" + item.sku} className="disclaimer d-flex"
                  data-tooltip-content={`DISCLAIMER: This product information is sourced from 
                  ${item.retailer ? item.retailer : `the retailer identified by the logo`}
                  . We do not claim ownership or endorse the accuracy of this information. You are encouraged to verify details with the retailer and review their terms & conditions. To learn more, please click on Terms and Conditions link at the bottom of this page`}>
                  <FontAwesomeIcon icon={faCircleExclamation} size="sm" className="fastyle align-bottom" />
                </button>
              {/* </div> */}
            </div>
              
          </div>
        </Card.Footer>
        {/* {retailerLogoPortal}
        {pricePortal} */}
      </Card>
    ) : (
    <Card>
      <Card.Body>
      <Row>
        <Col xs={4} sm={3} md={2} lg={2} xl={2} xxl={2}>
            <Row className="align-items-center">
            <Col className="flex-column">
              <Price
                regularPrice={item.regularPrice}
                salePrice={item.salePrice}
                onClick={() => openExternalLink(item.merchantUrl)}
              />
            <div className='img-wrapper'>
              <Card.Img className="img-fluid"
                src={item.imageUrl}
                onClick={() => openExternalLink(item.merchantUrl)}
              />
            </div>
            { screenSizeContext.isScreenWidthXSmall && (
               <div className="retailer-logo-parent-xs " id={MEDIA_XSMALL_RETAILER_LOGO_ID} >
                  <RetailerLogo websiteCode={item.websiteCode as RetailerLogosName} />
               </div>
            )}
            </Col>
            </Row>
        </Col>
        <Col xs={8} sm={9} md={10} lg={10} xl={10} xxl={10} className="d-flex flex-column justify-content-between"> {/* this column has 3 rows - 1. title, 2. content, 3 footer with links to show and compare */}
            <Row>
            <Col>
              <Button className="button-link"
                    variant="link"
                    onClick={() => openExternalLink(item.merchantUrl)}> 
                {item.itemName}
              </Button>
            </Col>
            </Row>
            {screenSizeContext.isScreenWidthLarge ? (
            <Row className="align-items-center">
              <Col xs={0} sm={0} md={5} lg={5} xl={5} xxl={5}>
                  <div className="card-text">
                    RAM: {formatValue(item.ramSize, `${item.ramSize}GB`)}
                  </div>
                  <div className="card-text">
                    CPU Speed:{' '}
                    {formatValue(item.cpuSpeed, `${item.cpuSpeed}GHz`)}
                  </div>
                  <div className="card-text">
                    Storage:{' '}
                    {formatValue(item.storageSize, `${item.storageSize}GB`)}
                  </div>
              </Col>
              <Col xs={0} sm={0} md={5} lg={5} xl={5} xxl={5}>
                  <div className="card-text">
                    Screen resolution: {formatValue(item.screenResolution)}
                  </div>
                  <div className="card-text">
                    Display size: {formatValue(item.screenSize)}
                  </div>
                  <div className="card-text">
                    CPU brand: {formatValue(item.cpuBrand)}
                  </div>
              </Col>
               <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2}> {/*For some reason, d-none, d-<size>-block dont work on row*/}
                 {/* <div id={ACTIONS_RETAILER_LOGO_ID} /> */}
                 <RetailerLogo websiteCode={item.websiteCode as RetailerLogosName} />
               </Col>
            </Row>
            ) : (
            <Row className="pt-2">
               <Col sm={8} xs={12}> {/* adding g-n classes to adjust bootstrap gutter makes the columns go beyond its container */}
                  <div className="card-text">
                    RAM: {formatValue(item.ramSize, `${item.ramSize}GB`)}  |  CPU
                    Speed: {formatValue(item.cpuSpeed, `${item.cpuSpeed}GHz`)}  |
                    Storage: {formatValue(item.storageSize, `${item.storageSize}GB`)}  |
                    Screen resolution: {formatValue(item.screenResolution)}  |
                    Display size: {formatValue(item.screenSize)}  |  CPU brand:{' '}
                    {formatValue(item.cpuBrand)}
                  </div>
                </Col>
                { !screenSizeContext.isScreenWidthLarge && !screenSizeContext.isScreenWidthXSmall && (
                  <Col>
                    <div className="retailer-logo-parent-xs " id={MEDIA_XSMALL_RETAILER_LOGO_ID} >
                      <RetailerLogo websiteCode={item.websiteCode as RetailerLogosName} />
                    </div>
                  </Col>
            )}
            </Row>
            )}
            <Row>
              
               <Col>
               <div className="actions flex-row pt-2 gap-3 float-end">
                  <div className="actions-compare px-2">
                  <input className="form-check-input" type="checkbox" 
                  disabled={disableCheckBox()}
                  checked={findIndex() > -1 ? true : false}
                  onChange={(a) => updateItemsToCompare(a.target.checked)}
                  id={item.sku}
                  /> <label htmlFor={item.sku} className="form-check-label card-text">Compare</label>
                  </div>
                  <div className="actions-show-details d-flex justify-content-center align-items-center pt-1">
                  <Button className="button-link"
                    variant="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick();
                    }}> Show Details </Button>
                  <div className="z-3">
                     <ReactTooltip id={"disclaimer_" + item.sku} className="ttclass" clickable={true}/>
                     <button className="disclaimer"  data-tooltip-id={"disclaimer_" + item.sku} data-tooltip-content="DISCLAIMER: The product information on this website is provided by the 3rd party merchants/retailers as indicated by the logo.">
                     <FontAwesomeIcon icon={faCircleExclamation} size="sm" className="fastyle" /></button>
                  </div>
                  </div>
               </div>
               </Col>
            </Row>
        </Col>
      </Row>
      {/* {retailerLogoPortal}
      {pricePortal} */}
      </Card.Body>  
    </Card>
    )}
  </div>
  );
};
