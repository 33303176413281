import { Dispatch } from "react";
import { IFilterStatus, initialFilterStatus, rangeInputConstant } from "../components/range-inputs/NewRangeInput";
import { paramKeys, rangeInputParamKey } from "./constant";

const formatter = new Intl.NumberFormat('en-US'); // en-US

export const formatNumberDecimals = (number: number, decimals = 2): number => {
  const formattedNumber = number
    .toString()
    .match(new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`))?.[0];
  return formattedNumber ? parseFloat(formattedNumber) : number;
};

export const formatNumber = (number: number, decimals = 2): string => {
  return formatter.format(formatNumberDecimals(number, decimals));
};

export const formatNumericString = (numericString: string): number => {
  const formatted = numericString.replace(',', '');
  return formatted && !isNaN(Number(formatted)) ? parseFloat(formatted) : 0;
};

// set min & max price from radio buttons
export const setMinMax = (val: string , minSetter:Dispatch<any>, maxSetter:Dispatch<any>) => {
  const minMax: string[] = val?.split(':');
  minSetter(Number(minMax[0]));
  maxSetter(Number(minMax[1]));
}

export const rangeInputParamString = (rangeInput:IFilterStatus) =>{
  let paramString = "";
  const seprator = "_"
  for(const key in rangeInput){
    if(rangeInput[key].current === "input"){
      paramString += `${seprator}${rangeInputParamKey[key]}`
    }
  }
  return paramString;
}

export const rangeInputParamExchanger = (paramString:string , inputSetter:Dispatch<any> , valuExchange:any , searchParams:any) =>{
const tempObj = JSON.parse(JSON.stringify(initialFilterStatus));
let tempchanged = false;
for(const key in tempObj){
  if(paramString.includes(rangeInputParamKey[key])){
    const minValue = searchParams.get(paramKeys[key].min);
    const maxValue = searchParams.get(paramKeys[key].max);
    
    tempObj[key].current = 'input';
    tempObj[key].values.min = minValue ? minValue:"";
    tempObj[key].values.max = maxValue ? maxValue: "";
    if(!tempchanged){
      tempchanged = true;
    }
  }
}

if(!paramString.includes(rangeInputParamKey[rangeInputConstant.StorageSize])){
  const minValue = searchParams.get(paramKeys[rangeInputConstant.StorageSize].min);
  const maxValue = searchParams.get(paramKeys[rangeInputConstant.StorageSize].max);
  tempObj[rangeInputConstant.StorageSize].current = 'checkbox';
  tempObj[rangeInputConstant.StorageSize].values.min = minValue ? minValue:"";
  tempObj[rangeInputConstant.StorageSize].values.max = maxValue ? maxValue: "";
  tempchanged = true;
}

if(tempchanged){
  inputSetter(tempObj);
}
}

export function validateFiltersFromUrl() {
  // Get query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);

  // Extract relevant parameters
  const priceLow = parseFloat(urlParams.get('prl') || "0");
  const priceHigh = parseFloat(urlParams.get('prh') || "0");
  const ramLow = parseFloat(urlParams.get('raml') || "0");
  const ramHigh = parseFloat(urlParams.get('ramh') || "0");
  const cpuLow = parseFloat(urlParams.get('cpul') || "0");
  const cpuHigh = parseFloat(urlParams.get('cpuh') || "0");
  const storageLow = parseFloat(urlParams.get('sszl') || "0"); // Add storage low
  const storageHigh = parseFloat(urlParams.get('sszh') || "0"); // Add storage high

  const errors = [];

  // Validate if high values are less than low values
  if (priceHigh < priceLow) {
      errors.push("Price high value should be greater than or equal to low value.");
  }
  if (ramHigh < ramLow) {
      errors.push("RAM high value should be greater than or equal to low value.");
  }
  if (cpuHigh < cpuLow) {
      errors.push("CPU high value should be greater than or equal to low value.");
  }
  if (storageHigh < storageLow) {
      errors.push("Storage size high value should be greater than or equal to low value."); // Storage validation
  }

  return errors;
}
