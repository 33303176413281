import React, { useState } from 'react';

interface ExpandableTextProps {
  text: string;
  classes?:string;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ text , classes }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText = text.length > 250 ? text.substring(0, 250) + '...' : text;

  return (
    <div className={`${classes}`} >
    
        {isExpanded ? text : truncatedText}{" "}
      
      {text.length > 250 && (
        <a href="#" onClick={(e) => { e.preventDefault(); toggleExpand(); }} className=''>
          {isExpanded ? 'less' : 'more'}
        </a>
      )}
    </div>
  );
};

export default ExpandableText;
