import { Item, ItemDetails } from './components/item-card/ItemCard';
import { dummyitems, dummydetails } from './dummydata';

// TO-DO: move this to an env varible
const ROOT_API_URL = process.env.REACT_APP_ROOT_API_URL;
export const TESTING = process.env.REACT_APP_TESTING_MODE === "TESTING";

export const searchItems = async (
  queryParams: string,
  testing = false,
): Promise<{
  items: Item[];
  numberOfPages: number;
  totalRecords: number;
}> => {

  // For testing locally
  if (testing || TESTING){
    return {
      items: dummyitems,
      numberOfPages: 3,
      totalRecords: 75,
    };
  }

  try {
    const rawResponse = await fetch(
      `${ROOT_API_URL}/api/search${queryParams}`,
      {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          //   'Access-Control-Allow-Origin': 'http://localhost:3000',
          //   'Access-Control-Allow-Credentials': 'true',
        },
      },
    );
    const data = await rawResponse.json();
    if (data?.resultItems?.length > 0) {
      return {
        items: data.resultItems,
        numberOfPages: data.totalPages,
        totalRecords: data.totalRecords,
      };
    } else throw Error('');
  } catch (error) {
    return {
      items: [],
      numberOfPages: 1,
      totalRecords: 1,
    };
  }
};

export const searchItemDetails = async (
  itemSku: string,
  itemWebsiteCode: string,
  testing = false,
): Promise<ItemDetails | null> => {
  // console.log('GETTING ITEM DETAILS: ', itemSku);

  // For testing locally
  if (testing) return dummydetails;

  try {
    const rawResponse = await fetch(
      `${ROOT_API_URL}/api/details?wc=${itemWebsiteCode}&sku=${itemSku}`,
      {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          //   'Access-Control-Allow-Origin': 'http://localhost:3000',
          //   'Access-Control-Allow-Credentials': 'true',
        },
      },
    );
    const data = await rawResponse.json();
    if (data) return data;
    throw Error('');
  } catch (error) {
    return null;
  }
};
