import { rangeInputConstant } from "../components/range-inputs/NewRangeInput";


export const rangeInputParamKey = {
[rangeInputConstant.Price]:"p",
[rangeInputConstant.RAMSize]:"rs",
[rangeInputConstant.CPUSpeed]:"cs",
[rangeInputConstant.StorageSize]:"ss",
}
export const paramKeys = {
[rangeInputConstant.Price]:{min:'prl' , max:"prh"},
[rangeInputConstant.RAMSize]:{min:'raml' , max:"ramh"},
[rangeInputConstant.CPUSpeed]:{min:'cpul' , max:"cpuh"},
[rangeInputConstant.StorageSize]:{min:'sszl' , max:"sszh"},
}