import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ItemsToCompareContext from '../../contexts/ItemsToCompareContext';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import { Item, ItemDetails, valueIsNull } from '../item-card/ItemCard';
import './CompareTable.scss';
import { searchItemDetails, TESTING } from '../../queries';
import { ItemColumn } from '../../item-column/ItemColumn';
import { CPUBrands } from '../sidebar/filterInterfaces';
import { Footer } from '../footer/Footer';
import CustomTable from './CustomTable';

export const goBack = () => {
  window.history.go(-1); 
  return false;
};

export const CompareTable = () => {
  const { itemsToCompare, setItemsToCompare } = useContext(
    ItemsToCompareContext,
  );
  const [searchParams] = useSearchParams();
  const screenSizeContext = useContext(ScreenSizeContext);
  const [rowsToHide, setRowsToHide] = useState<(keyof ItemDetails)[]>([]);
  useEffect(() => {
    const auxRowsToHide: (keyof ItemDetails)[] = [];
    itemsToCompare.forEach((itemToCompare) => {
      for (const key in itemToCompare.details) {
        const itemDetailKey = key as keyof ItemDetails;
        const keyExists = !!rowsToHide.find((rth) => rth === key);
        //if (valueIsNull(itemToCompare?.details?.[itemDetailKey]) && !keyExists){
        //  auxRowsToHide.push(itemDetailKey);
		//}
      }
    });
    setRowsToHide(auxRowsToHide);
  }, [itemsToCompare]);

  useEffect(() => {
    const skus = searchParams.get('skus');
    const websitecodes = searchParams.get('websitecodes');

    if (skus && websitecodes) {
      const skusList = skus.split(',');
      const websitecodesList = websitecodes.split(',');

      const call = async () => {
        const resultPromise = skusList.map(async (skul, index) => {
          const itemToCompare = itemsToCompare.find(
            (itc) =>
              itc.sku === skul && itc.websiteCode === websitecodesList?.[index],
          );
          if (!itemToCompare) {
            const details = await searchItemDetails(
              skul,
              websitecodesList?.[index],
              TESTING,
            );
            const item: Item = {
              sku: details?.sku ?? '',
              itemName: details?.name ?? '',
              regularPrice: details?.regularPrice?.toString() ?? '',
              salePrice: details?.salePrice?.toString() ?? '',
              description: details?.description ?? '',
              itemUrl: details?.imageURL ?? '',
              imageUrl: details?.imageURL ?? '',
              sourceWebsiteCode: '',
              merchantsItemId: '',
              itemShortDescr: '',
              longDescr: details?.description ?? '',
              cpuBrand: CPUBrands.INTEL,
              upc: details?.upc ?? '',
              thumbnailImageUrl: details?.thumbnailImageURL ?? '',
              merchantUrl: details?.thumbnailImageURL ?? '',
              logoFileName: '',
              affiliateId: '',
              cpuSpeed: details?.cpuSpeed ?? '',
              ramSize: details?.ramSize ?? '',
              storageSize: details?.storageSize ?? '',
              screenSize: details?.screenSize ?? '',
              screenResolution: details?.screenResolution ?? '',
              websiteCode: details?.websiteCode ?? '',
              details: details ?? undefined,
            };
            if (item.details) {
              return item;
            } else return null;
          }
        });
        const result = await Promise.all(resultPromise);
        const resultFiltered = result.filter((r) => r) as Item[];
        setItemsToCompare([...itemsToCompare, ...resultFiltered]);
      };
      call();
    }
  }, [searchParams]);

  const hiddenCol = useCallback(
    (index: number): boolean => {
      return !screenSizeContext.isScreenWidthLarge && index === 2;
    },
    [screenSizeContext.isScreenWidthLarge],
  );
  return (
    <div
      className={
        'compare-table w-100 h-100 border border-solid d-flex flex-column  ' +
        (screenSizeContext.isScreenWidthExtraLarge
          ? 'xl-compare-table'
          : screenSizeContext.isScreenWidthLarge
          ? 'lg-compare-table'
          : 'small-compare-table')
      }
    >
      <div className="header p-2">
        <div>Compare Specs</div>
        <div className="hcustom" onClick={() => goBack()}>
          Cancel Compare
        </div>
      </div>
      {itemsToCompare.length > 0 && (
        <CustomTable compareItemArray={itemsToCompare}/>
      )}
    <Footer />
    </div>
  );
};
